import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Checkbox, FormControl, FormGroup, InputLabel} from "@mui/material";
import Card from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { Buffer } from 'buffer';
import xtv from '../images/img.png'
import jiu from '../images/jiu_logo_colored.png'
import rtm from '../images/img_2.png'
import sovga from '../images/gift.jpg'
import regionsFile from '../data/regions.json'
import districtsFile from '../data/districts.json'
import oferta from '../documents/jiu_oferta.pdf'
// import PhoneInput from 'react-phone-number-input/input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'© '}
            <Link color="inherit" href="https://jiuuni.uz/" target="_blank">
                JIUUNI.uz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SurveyRu() {
    const [region, setRegion] = React.useState('')
    const [district, setDistrict] = React.useState('')
    const [alldistricts, setAlldistricts] = React.useState(districtsFile)
    const [districts, setDistricts] = React.useState('')
    const [formShown, setformShown] = React.useState(false)
    const [phone, setPhone] = React.useState("")
    const [pphone, setPphone] = React.useState("")
    const [school, setSchool] = React.useState("")
    const [school_name, setSchoolName] = React.useState("")
    const [fullname, setFullname] = React.useState("")
    const [agree, setAgree] = React.useState(false)
    let navigate = useNavigate()

    const onChangePhone = event => {
        setPhone(event);
    };
    const onChangeAgree = event => {
        setAgree(event.target.checked);
    };
    const onChangePphone = event => {
        setPphone(event);
    };
    const onChangeFullname = event => {
        setFullname(event.target.value);
    };

    const onChangeSchool = event => {
        setSchool(event.target.value);
    };

    const onChangeSchoolName = event => {
        setSchoolName(event.target.value);
    };
    const [loading, setLoading] = React.useState(false);

    const handleShowForm = (event) => {
        event.preventDefault();
        setLoading(false);

        let err = '';
        if (region === '') err = "<p>Пожалуйста, выберите регион/город!</p>"
        if (district === '') err += "<p>Пожалуйста, выберите район!</p>"
        if (fullname === '') err += "<p>Пожалуйста, введите свое полное имя!</p>"
        if (school === '') err += "<p>Пожалуйста, введите свою школу!</p>"
        if (school_name === '') err += "<p>IПожалуйста, введите название свою школу (школа/интернат/ИДУМ...)!</p>"
        if (agree === false) err += "<p>Подтвердите свое согласие с условиями оферты!</p>"
        if (phone === undefined || phone < 9) err += "<p>Пожалуйста, введите номер телефона школьника правильно!</p>"
        if (pphone === undefined || phone.length < 9) err += "<p>Пожалуйста, введите номер телефона родителя правильно.</p>"
        let errors = document.getElementById('errors');
        if(!err) {setformShown(!formShown); errors.innerHTML = '';}
        else errors.innerHTML = err;
        err = ''
    };

    const handleChangeRegion = (event) => {
        setRegion(event.id);
        const districtsList = alldistricts.filter(districtOne => districtOne.region_id === event.id)
        setDistricts(districtsList)
        setDistrict('');
    };

    const handleChangeDistrict = (event) => {
        const districtsList = alldistricts.filter(districtOne => districtOne.id === event.id)
        setDistrict(districtsList);
    };

    const username = 'ApiAdmin'
    const password = '*&87Y*&y98U89uj(*U'

    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        let dataStringify = JSON.stringify({
            region: data.get('region'),
            district: data.get('district'),
            school: school,
            school_name: school_name,
            fullname: fullname,
            st_phone: pphone,
            parents_phone: phone,
            agree: agree,
            sex: data.get('sex'),
            school_class: data.get('school_class'),
            which_otm: data.get('which_otm'),
            otm_path: data.get('otm_path'),
            english_level: data.get('english_level'),
            math_level: 1,
            prefer_contract_otm: data.get('prefer_contract_otm'),
            time_for_paid_courses: data.get('time_for_paid_courses')
        });
        console.log(dataStringify)
        let config = {
            method: 'post',
            // url: 'http://api.javoblar.uz/api/survey',
            // url: 'http://localhost:3003/api/survey',
            url: 'https://core.uz/api/survey',
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${token}`
                },
            data : dataStringify
        };

        axios(config)
            .then(function (response) {
                const sales = response.data
                navigate(`/ru/confirm-ru`);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" sx={{pb: 10, px: 1}}>
                <Grid container
                      spacing={2}
                      sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
                      direction="row"
                      alignItems="center"
                >

                    <Grid item xs={12} md={4}>
                        <img
                            src={xtv}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>МИНИСТЕРСТВО ДОШКОЛЬНОГО И ШКОЛЬНОГО ОБРАЗОВАНИЯ УЗБЕКИСТАНА</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            src={jiu}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>ЯПОНСКИЙ МЕЖДУНАРОДНЫЙ УНИВЕРСИТЕТ В САМАРКАНДЕ</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            src={rtm}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>РЕСПУБЛИКАНСКИЙ ЦЕНТР ПРОФЕССИОНАЛЬНОЙ ОРИЕНТАЦИИ И ПСИХОЛОГО-ПЕДАГОГИЧЕСКОЙ ДИАГНОСТИКИ УЧАЩИХСЯ</Typography>
                    </Grid>
                </Grid>
                <Card sx={{ width: '100%', mt: 3, textAlign: 'center' }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Анкета
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Проводится Министерством дошкольного и школьного образования Республики Узбекистан совместно с Республиканским центром профессиональной ориентации и психолого-педагогической диагностики учащихся и Японским международным университетом (JIU). Целью данной анкеты является повышение знаний и умений учащихся 10-х и 11-х классов по профориентации.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Paper variant="elevation" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
                                    {/*<form onSubmit={handleSubmit}>*/}
                                        <Grid container spacing={2} className={formShown? 'hidden' : 'shown'} sx={{ mb: 2 }}>
                                            <Grid item xs={12}>
                                                <InputLabel id="region-label">Регион/Город</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        required
                                                        sx={{backgroundColor: 'black'}}
                                                        labelId="region-label"
                                                        name="region"
                                                        style={{'display': 'block'}}
                                                        options={regionsFile}
                                                        placeholder="Выбирать ..."
                                                        // value={region}
                                                        onChange={handleChangeRegion}
                                                        getOptionLabel={(option) => option.name_ru}
                                                        getOptionValue={(option) => option.id}
                                                        fullWidth
                                                    />

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel id="district-label">Выбирать район</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="district-label"
                                                        required
                                                        // autoComplete="given-name"
                                                        name="district"
                                                        placeholder="Выбирать..."
                                                        fullWidth
                                                        id="district"
                                                        label="Tumanni tanlang"
                                                        options={districts}
                                                        value={district}
                                                        getOptionLabel={(option) => option.name_ru}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={handleChangeDistrict}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <TextField
                                                    type="number"
                                                    required
                                                    fullWidth
                                                    id="school"
                                                    inputProps={{ maxLength: 10 }}
                                                    label="Школа (1/2/3...)"
                                                    name="school"
                                                    value={school}
                                                    onChange={onChangeSchool}
                                                />
                                            </Grid>

                                            <Grid item xs={8}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="school_name"
                                                    label="Название школы (школа/интернат/ИДУМ...)!"
                                                    name="school_name"
                                                    inputProps={{ maxLength: 254 }}
                                                    value={school_name}
                                                    onChange={onChangeSchoolName}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="fullname"
                                                    label="Имя Фамилия"
                                                    name="fullname"
                                                    inputProps={{ maxLength: 100 }}
                                                    value={fullname}
                                                    onChange={onChangeFullname}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                Номер телефона школьника
                                                <PhoneInput
                                                    inputStyle={{'width':'100%', 'borderRadius':'3px', 'height': '50px', 'fontSize': '16px'}}
                                                    country={'uz'}
                                                    required
                                                    id="st_phone"
                                                    label="Номер телефона школьника"
                                                    name="st_phone"
                                                    inputProps={{ maxLength: 100 }}
                                                    disableCountryCode={true}
                                                    placeholder={"99 111 22 33"}
                                                    onlyCountries={["uz"]}
                                                    value={pphone}
                                                    onChange={onChangePphone}
                                                />

                                            </Grid>

                                            <Grid item xs={12}>
                                                Номер телефона родителя
                                                <PhoneInput
                                                    country={'uz'}
                                                    required
                                                    disableCountryCode={true}
                                                    inputStyle={{'width':'100%', 'borderRadius':'3px', 'height': '50px', 'fontSize': '16px'}}
                                                    inputProps={{ maxLength: 100 }}
                                                    id="parents_phone"
                                                    label="Номер телефона родителя"
                                                    name="parents_phone"
                                                    placeholder={"99 111 22 33"}
                                                    onlyCountries={["uz"]}
                                                    value={phone}
                                                    onChange={onChangePhone}
                                                />

                                                <FormGroup>
                                                    <FormControl>
                                                        <Link href={oferta} sx={{ mt: 2 }} target="_blank">Оферта</Link>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name="agree"
                                                                value={agree}
                                                                onChange={onChangeAgree}
                                                            />}
                                                                          label="Согласен с условиями оферты."/>
                                                    </FormControl>
                                                </FormGroup>

                                                <Typography
                                                style={{'color': 'red'}}
                                                id={"errors"}
                                                >

                                                </Typography>

                                                {!formShown && <Button
                                                    onClick={handleShowForm}
                                                    type='submit'
                                                    variant="contained"
                                                    sx={{mt: 3, mb: 2, px: 3}}
                                                >Продолжить
                                                </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} className={!formShown? 'hidden' : 'shown'}>
                                      <Box
                                            sx={{
                                                marginTop: 3,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                px: 2
                                            }}>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="sex-label">Ваш пол</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="sex-label"
                                                            name="sex"
                                                        >
                                                            <FormControlLabel value="female" name="sex" control={<Radio />} label="Женщина" />
                                                            <FormControlLabel value="male" name="sex" control={<Radio />} label="Мужчина" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="school_class-label">Ваш класс</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="school_class-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Выбирать..."
                                                            name="school_class"
                                                            required
                                                            fullWidth
                                                            id="school_class"
                                                            label="Sinfingiz"
                                                            options={[
                                                                // { value: 8, label: '8 - Sinf' },
                                                                // { value: 9, label: '9 - Sinf' },
                                                                { value: 10, label: '10 - класс' },
                                                                { value: 11, label: '11 - класс' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="which_otm-label">В какой университет вы хотите поступить после окончания школы?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="which_otm-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Выбирать..."
                                                            name="which_otm"
                                                            required
                                                            fullWidth
                                                            id="which_otm"
                                                            label="В какой университет вы хотите поступить после окончания школы?"
                                                            options={[
                                                                { value: 'davlat_otmlari', label: 'Государственные вузы' },
                                                                { value: 'xalqaro_otmlar', label: 'Международные вузы' },
                                                                { value: 'texnikum', label: 'Техникум' },
                                                                { value: 'chet_elga', label: 'Хочу учиться за границей' },
                                                                { value: 'kirmoqchi_emasman', label: 'Не хочу учиться' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="otm_path-label">На какую специальность вы хотите поступить?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="otm_path-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Выбирать..."
                                                            name="otm_path"
                                                            required
                                                            fullWidth
                                                            id="otm_path"
                                                            label="На какую специальность вы хотите поступить?"
                                                            options={[
                                                                { value: 'menejment', label: 'Бизнес-администрирование' },
                                                                { value: 'iqtisod', label: 'Экономика' },
                                                                { value: 'ekonomika', label: 'Финансы' },
                                                                { value: 'it', label: 'ИТ' },
                                                                { value: 'pedagogika', label: 'Педагогика' },
                                                                { value: 'huquqshunoslik', label: 'Юриспруденция' },
                                                                { value: 'tibbiyot', label: 'Медицина' },
                                                                { value: 'turizm', label: 'Туризм' },
                                                                { value: 'sanat', label: 'Туризм' },
                                                                { value: 'qishloq_xojaligi', label: 'Сельское хозяйство' },
                                                                { value: 'arxitektura', label: 'Архитектура' },
                                                                { value: 'muhandislik', label: 'Инжиниринг' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="english_level-label">Насколько хорошо вы знаете английский язык?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="english_level-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Выбирать..."
                                                            name="english_level"
                                                            required
                                                            fullWidth
                                                            id="english_level"
                                                            label="Насколько хорошо вы знаете английский язык?"
                                                            options={[
                                                                { value: 'bilmayman', label: 'Не знаю' },
                                                                { value: 'beginner', label: 'Beginner' },
                                                                { value: 'elementary', label: 'Elementary' },
                                                                { value: 'pre_intermediate', label: 'Pre-Intermediate' },
                                                                { value: 'intermediate', label: 'Intermediate' },
                                                                { value: 'advanced', label: 'Advanced' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="prefer_contract_otm-label">Если вас зачислят в выбранный вами ВУЗ на основании контракта, какую сумму контрактного вознаграждения вы можете заплатить?</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="prefer_contract_otm-label"
                                                            name="prefer_contract_otm"
                                                        >
                                                            <FormControlLabel value="imkoniyatim_yoq" name="prefer_contract_otm" control={<Radio />} label="у меня нет шансов" />
                                                            <FormControlLabel value="5_10_million" name="prefer_contract_otm" control={<Radio />} label="5-10 миллионов" />
                                                            <FormControlLabel value="10_20_million" name="prefer_contract_otm" control={<Radio />} label="10-20 миллионов" />
                                                            <FormControlLabel value="20_plus_million" name="prefer_contract_otm" control={<Radio />} label="20+ миллионов" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="time_for_paid_courses-label">Сколько времени вы тратите на платные курсы вне занятий?</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="time_for_paid_courses-label"
                                                            name="time_for_paid_courses"
                                                        >
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_6_12" control={<Radio />} label="6-12 месяцев " />
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_3_6" control={<Radio />} label="3-6 месяцев " />
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_1_3" control={<Radio />} label="1-3 месяцев " />
                                                            <FormControlLabel name="time_for_paid_courses" value="not_spend" control={<Radio />} label="не трачу" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>

                                                {formShown && <Button
                                                    onClick={handleShowForm}
                                                    // fullWidth
                                                    variant="outlined"
                                                    sx={{mt: 3, mb: 2, px: 5}}
                                                >Назад
                                                </Button>
                                                }
                                                    <LoadingButton
                                                        type="submit"
                                                        loading={loading}
                                                        loadingPosition="end"
                                                        endIcon={<SendIcon />}
                                                        variant="contained"
                                                        sx={{mt: 3, ml: 2, mb: 2, px: 5}}
                                                    >
                                                        Отправить
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {/*</form>*/}
                                </Box>
                                <Copyright />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={sovga}
                                loading="lazy"
                                style={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}