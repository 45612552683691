import {Alert, AlertTitle, CardActionArea} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import xtv from "../images/img.png";
import jiu from '../images/jiu_logo_colored.png'
import rtm from "../images/img_2.png";
import cnfrm from "../images/img_3.png";
import cnfrm1 from "../images/confirm-2.png";
import cnfrm2 from "../images/confirm-3.png";
import ListItem from "@mui/material/ListItem";
import {Link} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ReactSwipe from 'react-swipe';
import Button from "@mui/material/Button";

function ConfirmRu() {
    let reactSwipeEl;
    return <Container component="main" maxWidth="lg" sx={{pb: 10}}>
        <Grid container
              spacing={2}
              sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
              direction="row"
              alignItems="center"
        >

            <Grid item xs={12} md={4}>
                <img
                    src={xtv}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>МИНИСТЕРСТВО ДОШКОЛЬНОГО И ШКОЛЬНОГО ОБРАЗОВАНИЯ УЗБЕКИСТАНА</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <img
                    src={jiu}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>ЯПОНСКИЙ МЕЖДУНАРОДНЫЙ УНИВЕРСИТЕТ В САМАРКАНДЕ</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <img
                    src={rtm}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>РЕСПУБЛИКАНСКИЙ ЦЕНТР ПРОФЕССИОНАЛЬНОЙ ОРИЕНТАЦИИ И ПСИХОЛОГО-ПЕДАГОГИЧЕСКОЙ ДИАГНОСТИКИ УЧАЩИХСЯ</Typography>
            </Grid>
        </Grid>
        <Alert severity="success" sx={{mt: 3}}>
            <AlertTitle><strong>Отправлено!</strong></AlertTitle>
            Уважаемый школьник, поздравляем вас с успешной регистрацией!

            <ListItem sx={{p: 0, textDecoration: 'none', mt: 2}}>
                <Link to="/ru" underline="none">
                    <ListItemText primary="Новый опрос →"/>
                </Link>
            </ListItem>
        </Alert>


        <Grid container
              spacing={2}
              sx={{ backgroundColor: 'white', width: '100%', mt: 3, pb: 5, ml: 0, p: 3}}
        >
            <div style={{overflow: 'hidden'}}>
                <ReactSwipe
                    className="wrapper"
                    swipeOptions={{ continuous: true, auto: true, speed: 3000 }}
                    ref={el => (reactSwipeEl = el)}
                >
{/*
                    <div style={{backgroundImage: 'url('+cnfrm+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
*/}
                    <div style={{backgroundImage: 'url('+cnfrm1+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
                    <div style={{backgroundImage: 'url('+cnfrm2+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
                </ReactSwipe>

                <Button
                    onClick={() => reactSwipeEl.prev()}
                    // fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2, px: 3, py: 1}}
                >&#8592;
                </Button>
                <Button
                    onClick={() => reactSwipeEl.next()}
                    // fullWidth
                    variant="contained"
                    id={"next-btn"}
                    sx={{mt: 3, mb: 2, px: 3, py: 1}}
                    style={{float: 'right'}}
                >&#8594;
                </Button>

            </div>
            <Typography gutterBottom variant="h6" component="div">
                Мы думаем, что вы обязательно добьетесь успеха в выбранной профессии.
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
                Конкурс анкет проводится в произвольной форме, и каждый студент торжественно награждается представителями Министерства дошкольного и школьного образования, Республиканского диагностического центра и Университета JIU.
            </Typography>
        </Grid>
        </Container>
}

export default ConfirmRu