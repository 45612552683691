import {Outlet, Link} from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Container, Divider} from "@mui/material";
import { Home,Info } from "@mui/icons-material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";

const Layout = () => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, pt: 3}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <Divider />
            <ListItem button>
                <ListItemIcon>
                    <Home />
                </ListItemIcon>
                <Link to="/">
                    <ListItemText primary="Bosh sahifa"/>
                </Link>
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemIcon>
                    <Info />
                </ListItemIcon>
                <Link to="/about">
                    <ListItemText primary="Loyiha haqida"/>
                </Link>
            </ListItem>
            <Divider />
            {/*<ListItem button>*/}
            {/*    <ListItemIcon>*/}
            {/*        <ContactPage />*/}
            {/*    </ListItemIcon>*/}
            {/*    <Link to="/contact" underline="none">*/}
            {/*        <ListItemText primary="Aloqalar"/>*/}
            {/*    </Link>*/}
            {/*</ListItem>*/}

        </Box>
    );
    return (
        <><Container maxWidth="lg">
            <React.Fragment>
                <Box sx={{ flexGrow: 1, mx: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={toggleDrawer('left', true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Menu
                            </Typography>
                            <Link underline="none" to="/">
                                <Button variant="contained" sx={{mx: 1}}>
                                    O'zb
                                </Button>
                            </Link>
                            <Link underline="none" to="/ru">
                                <Button variant="contained" sx={{mx: 1}}>
                                    Рус
                                </Button>
                            </Link>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Drawer
                    anchor='left'
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                >
                    {list('left')}
                </Drawer>
            </React.Fragment>

            <Outlet />
        </Container>
        </>
    )
};

export default Layout;