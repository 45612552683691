import * as React from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Link from "@mui/material/Link";

function SalesFormView() {
    return (<View />);
}

function View() {
    let [salesData, setSalesData] = useState("");
    let params = useParams();
    // params.sId = 4
    const config = {
        method: 'get',
        url: 'http://localhost:8083/api/salesForm/'+params.sId,
        // data: {},
        headers: { }
    };

    let salesDataStr = '';
    axios(config)
        .then(function (response) {
            salesDataStr = JSON.stringify(response.data)
            setSalesData(salesDataStr)
            return <h1>NOT FOUND!</h1>
        })
        .catch(function (error) {
            console.log(error);
            return <h1>NOT FOUND!</h1>
        });

    function createData(manager_name, exam_date, selected_product, region, _customer_source, st_phone, st_parents_phone, st_address, st_surname, st_name, st_parents_name, st_passport_id, st_sex, st_birth_date, _file_st_passport, _file_st_diploma, st_student_role, _file_payment_check, updated_at, status, step_1, step_1_comment, step_2, step_2_comment, step_3, step_3_comment, updated_by, comment) {
        return { manager_name, exam_date, selected_product, region, _customer_source, st_phone, st_parents_phone, st_address, st_surname, st_name, st_parents_name, st_passport_id, st_sex, st_birth_date, _file_st_passport, _file_st_diploma, st_student_role, _file_payment_check, updated_at, status, step_1, step_1_comment, step_2, step_2_comment, step_3, step_3_comment, updated_by, comment};
    }
// console.log(salesData)
    let salesDataObj = {};
    if(salesData != null) {
        console.log(salesData)
        salesDataObj = JSON.parse(salesData);
    }else {
        salesDataObj = {};
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log(salesDataStr)
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         salesDataObj = JSON.parse(salesData);
    //     }, 2000);
    // }, []);

    const rows = [
        createData(salesDataObj.manager_name, salesDataObj.exam_date, salesDataObj.selected_product, salesDataObj.region, salesDataObj._customer_source, salesDataObj.st_phone, salesDataObj.st_parents_phone, salesDataObj.st_address, salesDataObj.st_surname, salesDataObj.st_name, salesDataObj.st_parents_name, salesDataObj.st_passport_id, salesDataObj.st_sex, salesDataObj.st_birth_date, salesDataObj._file_st_passport, salesDataObj._file_st_diploma, salesDataObj.st_student_role, salesDataObj._file_payment_check, salesDataObj.updated_at, salesDataObj.status, salesDataObj.step_1, salesDataObj.step_1_comment, salesDataObj.step_2, salesDataObj.step_2_comment, salesDataObj.step_3, salesDataObj.step_3_comment, salesDataObj.updated_by, salesDataObj.comment)
    ];

    // let result = undefined!==JSON.parse(salesData)[0]?JSON.parse(salesData)[0].manager_name:'No sale found by id: '+params.id

    // return <h1>{salesData?salesData:'Data with id: '+params.sId+' not found!'}</h1>;
    return (<>
        {salesData &&
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>manager_name</TableCell>
                            <TableCell>exam_date</TableCell>
                            <TableCell>selected_product</TableCell>
                            <TableCell>region</TableCell>
                            <TableCell>_customer_source</TableCell>
                            <TableCell>st_phone</TableCell>
                            <TableCell>st_parents_phone</TableCell>
                            <TableCell>st_address</TableCell>
                            <TableCell>st_surname</TableCell>
                            <TableCell>st_name</TableCell>
                            <TableCell>st_parents_name</TableCell>
                            <TableCell>st_passport_id</TableCell>
                            <TableCell>st_sex</TableCell>
                            <TableCell>st_birth_date</TableCell>
                            <TableCell>_file_st_passport</TableCell>
                            <TableCell>_file_st_diploma</TableCell>
                            <TableCell>st_student_role</TableCell>
                            <TableCell>_file_payment_check</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.manager_name}
                                </TableCell>
                                <TableCell align="right">{row.exam_date}</TableCell>
                                <TableCell align="right">{row.selected_product}</TableCell>
                                <TableCell align="right">{row.region}</TableCell>
                                <TableCell align="right">{row._customer_source}</TableCell>
                                <TableCell align="right">{row.st_phone}</TableCell>
                                <TableCell align="right">{row.st_parents_phone}</TableCell>
                                <TableCell align="right">{row.st_address}</TableCell>
                                <TableCell align="right">{row.st_surname}</TableCell>
                                <TableCell align="right">{row.st_name}</TableCell>
                                <TableCell align="right">{row.st_parents_name}</TableCell>
                                <TableCell align="right">{row.st_passport_id}</TableCell>
                                <TableCell align="right">{row.st_sex}</TableCell>
                                <TableCell align="right">{row.st_birth_date}</TableCell>
                                <TableCell align="right">{row._file_st_passport}</TableCell>
                                <TableCell align="right">{row._file_st_diploma}
                                </TableCell>
                                <TableCell align="right">{row.st_student_role}</TableCell>
                                <TableCell align="right">{row._file_payment_check}</TableCell>
                                <TableCell align="right">{row.updated_at}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                                <TableCell align="right">{row.step_1}</TableCell>
                                <TableCell align="right">{row.step_1_comment}</TableCell>
                                <TableCell align="right">{row.step_2}</TableCell>
                                <TableCell align="right">{row.step_2_comment}</TableCell>
                                <TableCell align="right">{row.step_3}</TableCell>
                                <TableCell align="right">{row.step_3_comment}</TableCell>
                                <TableCell align="right">{row.updated_by}</TableCell>
                                <TableCell align="right">{row.comment}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        }
        {!salesData &&
            <h1>Noooo!</h1>
        }
    </>);
}

export default SalesFormView
