import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Checkbox, FormControl, FormGroup, InputLabel} from "@mui/material";
import Card from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { Buffer } from 'buffer';
import xtv from '../images/img.png'
import jiu from '../images/jiu_logo_colored.png'
import rtm from '../images/img_2.png'
import sovga from '../images/gift.jpg'
import regionsFile from '../data/regions.json'
import districtsFile from '../data/districts.json'
import oferta from '../documents/jiu_oferta.pdf'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'© '}
            <Link color="inherit" href="https://jiuuni.uz/" target="_blank">
                JIUUNI.uz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function Survey() {
    const [regions, setRegions] = React.useState([]);
    const [selectedRegion, setSelectedRegion] = React.useState(null);
    const [districts, setDistricts] = React.useState([]);
    const [selectedDistrict, setSelectedDistrict] = React.useState(null);
    const [schools, setSchools] = React.useState([]);
    const [selectedSchool, setSelectedSchool] = React.useState(null);
    const [formShown, setformShown] = React.useState(false)
    const [phone, setPhone] = React.useState("")
    const [pphone, setPphone] = React.useState("")
    const [school, setSchool] = React.useState("")
    const [school_name, setSchoolName] = React.useState("")
    const [fullname, setFullname] = React.useState("")
    const [agree, setAgree] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [psychologists, setPsychologists] = React.useState([]);
    const [selectedPsychologist, setSelectedPsychologist] = React.useState(null);

    let navigate = useNavigate()

    const onChangePhone = event => {
        setPhone(event);
    };
    const onChangeAgree = event => {
        setAgree(event.target.checked);
    };
    const onChangePphone = event => {
        setPphone(event);
    };
    const onChangeFullname = event => {
        setFullname(event.target.value);
    };

    const onChangeSchoolName = event => {
        setSchoolName(event.target.value);
    };

    const handleShowForm = (event) => {
        event.preventDefault();
        setLoading(false);

        let err = '';
        if (fullname === '') err += "<p>Iltimos to'liq ismingizni kiriting!</p>"
        if (agree === false) err += "<p>Oferta shartlariga roziligingizni tasdiqlang!</p>"
        if (phone === undefined || phone < 9) err += "<p>Iltimos o'quvchi telefon raqamini to'g'ri kiriting!</p>"
        if (pphone === undefined || phone.length < 9) err += "<p>Iltimos ota-ona telefon raqamini to'g'ri kiriting</p>"
        let errors = document.getElementById('errors');
        if(!err) {setformShown(!formShown); errors.innerHTML = '';}
        else errors.innerHTML = err;
        err = ''
    };

    const endPoint = "http://45.80.151.117:8081"
    // Fetch regions initially
    React.useEffect(() => {
        axios.get(endPoint + '/api/regions')
            .then(response => {
                setRegions(response.data);
            })
            .catch(error => console.error('Error fetching regions', error));
    }, []);

    // Fetch districts when a region is selected
    React.useEffect(() => {
        if (selectedRegion) {
            axios.get(endPoint + `/api/districts/${selectedRegion.value}`)
                .then(response => {
                    setDistricts(response.data);
                    setSelectedDistrict(null);
                })
                .catch(error => console.error('Error fetching districts', error));
        }
    }, [selectedRegion]);

    // Fetch schools when a district is selected
    React.useEffect(() => {
        if (selectedDistrict) {
            axios.get(endPoint + `/api/schools/${selectedDistrict.value}`)
                .then(response => {
                    setSchools(response.data);
                    setSelectedSchool(null);
                })
                .catch(error => console.error('Error fetching schools', error));
        }
    }, [selectedDistrict]);

    // Fetch psychologists when a school is selected
    React.useEffect(() => {
        if (selectedSchool) {
            axios.get(endPoint + `/api/psychologists/${selectedSchool.value}`)
                .then(response => {
                    setPsychologists(response.data);
                })
                .catch(error => console.error('Error fetching psychologists', error));
        }
    }, [selectedSchool]);

    const handleRegionChange = option => {
        setSelectedRegion(option);
    };

    const handleDistrictChange = option => {
        setSelectedDistrict(option);
    };

    const handleSchoolChange = option => {
        setSelectedSchool(option);
    };

    const handlePsychologistChange = option => {
        setSelectedPsychologist(option);
        // console.log(psychologists[0].id)
    };


    const username = 'ApiAdmin'
    const password = '*&87Y*&y98U89uj(*U'

    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        let dataStringify = JSON.stringify({
            region: data.get('region'),
            district: data.get('district'),
            school: data.get('school_name'),
            school_name: data.get('school_name'),
            fullname: fullname,
            st_phone: pphone,
            parents_phone: phone,
            psychologist_id: psychologists[0].id,
            agree: agree,
            sex: data.get('sex'),
            school_class: data.get('school_class'),
            which_otm: data.get('which_otm'),
            otm_path: data.get('otm_path'),
            english_level: data.get('english_level'),
            math_level: 1,
            prefer_contract_otm: data.get('prefer_contract_otm'),
            time_for_paid_courses: data.get('time_for_paid_courses')
        });
        console.log(dataStringify)
        let config = {
            method: 'post',
            url: 'http://45.80.151.117:3004/api/survey',
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${token}`
                },
            data : dataStringify
        };

        axios(config)
            .then(function (response) {
                const sales = response.data
                navigate(`/confirm`);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" sx={{pb: 10, px: 1}}>
                <Grid container
                      spacing={2}
                      sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
                      direction="row"
                      alignItems="center"
                >

                    <Grid item xs={12} md={4}>
                        <img
                            src={xtv}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>OʼZBEKISTON RESPUBLIKASI MAKTABGACHA VA MAKTAB TAʼLIMI VAZIRLIGI</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            src={jiu}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>SAMARQAND SHAHRIDAGI YAPONIYA XALQARO UNIVERSITETI</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            src={rtm}
                            loading="lazy"
                            style={{width: '40%'}}
                        />
                        <Typography>O‘QUVCHILARNI KASB-HUNARGA YO‘NALTIRISH VA PSIХOLOGIK-PEDAGOGIK RESPUBLIKA TASHXIS MARKAZI</Typography>
                    </Grid>
                </Grid>
                <Card sx={{ width: '100%', mt: 3, textAlign: 'center' }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                So'rovnoma
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Oʻzbekiston Respublikasi Xalq taʼlimi vazirligi oʻquvchilarni kasb-hunarga yoʻnaltirish va psixologik-pedagogik Respublika Tashxis markazi hamda Yaponiya Xalqaro Universiteti (JIU) bilan hamkorlikda oʻtkazilmoqda. Bu soʻrovnomadan maqsad 10-sinf va 11-sinf oʻquvchilarini kasbga yoʻnaltirish boʻyicha bilim va koʻnikmalarini oshirishdir.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Paper variant="elevation" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
                                    {/*<form onSubmit={handleSubmit}>*/}
                                        <Grid container spacing={2} className={formShown? 'hidden' : 'shown'} sx={{ mb: 2 }}>
                                            <Grid item xs={12}>
                                                <InputLabel id="region-label">Viloyat/Shahar</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        required
                                                        sx={{backgroundColor: 'black'}}
                                                        labelId="region-label"
                                                        name="region"
                                                        style={{'display': 'block'}}
                                                        placeholder="Tanlang..."
                                                        options={regions.map(region => ({ value: region.id, label: region.name }))}
                                                        onChange={handleRegionChange}
                                                        value={selectedRegion}
                                                        fullWidth
                                                    />

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputLabel id="district-label">Tumanni tanlang</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="district-label"
                                                        required
                                                        name="district"
                                                        placeholder="Tanlang..."
                                                        fullWidth
                                                        id="district"
                                                        label="Tumanni tanlang"
                                                        options={districts.map(district => ({ value: district.id, label: district.name }))}
                                                        onChange={handleDistrictChange}
                                                        value={selectedDistrict}
                                                        isDisabled={!selectedRegion}                                                    />
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12}>
                                                <InputLabel id="chool-label">Maktabingizni tanlang</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="school_name"
                                                        label="Maktab Nomini tanlang"
                                                        name="school_name"
                                                        options={schools.map(school => ({ value: school.id, label: school.name }))}
                                                        onChange={handleSchoolChange}
                                                        value={selectedSchool}
                                                        placeholder="Select school..."
                                                        isDisabled={!selectedDistrict}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                {/* Psychologist Select Input */}
                                                <InputLabel id="psychologist-label">Psixologingizni tanlang</InputLabel>
                                                <FormControl fullWidth>

                                                    <Select
                                                        placeholder="Psixologingizni tanlang..."
                                                        id="psychologist_id"
                                                        options={psychologists.map(psychologist => ({ value: psychologist.id, label: psychologist.full_name }))}
                                                        onChange={handlePsychologistChange}
                                                        value={selectedPsychologist}
                                                        isDisabled={!selectedSchool}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputLabel id="fullname-label">To'liq ism familiyangiz</InputLabel>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="fullname"
                                                    // label="Ism familiya"
                                                    name="fullname"
                                                    inputProps={{ maxLength: 100 }}
                                                    value={fullname}
                                                    onChange={onChangeFullname}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                O'quvchi telefon raqami
                                                <PhoneInput
                                                    inputStyle={{'width':'100%', 'borderRadius':'3px', 'height': '50px', 'fontSize': '16px'}}
                                                    country={'uz'}
                                                    required
                                                    id="st_phone"
                                                    label="O'quvchi telefon raqami"
                                                    name="st_phone"
                                                    inputProps={{ maxLength: 100 }}
                                                    disableCountryCode={true}
                                                    placeholder={"99 111 22 33"}
                                                    onlyCountries={["uz"]}
                                                    value={pphone}
                                                    onChange={onChangePphone}
                                                />

                                            </Grid>

                                            <Grid item xs={12}>
                                                Ota-ona telefon raqami
                                                <PhoneInput
                                                    country={'uz'}
                                                    required
                                                    disableCountryCode={true}
                                                    inputStyle={{'width':'100%', 'borderRadius':'3px', 'height': '50px', 'fontSize': '16px'}}
                                                    inputProps={{ maxLength: 100 }}
                                                    id="parents_phone"
                                                    label="Ota-ona telefon raqami"
                                                    name="parents_phone"
                                                    placeholder={"99 111 22 33"}
                                                    onlyCountries={["uz"]}
                                                    value={phone}
                                                    onChange={onChangePhone}
                                                />

                                                <FormGroup>
                                                    <FormControl>
                                                        <Link href={oferta} sx={{ mt: 2 }} target="_blank">Oferta</Link>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name="agree"
                                                                value={agree}
                                                                onChange={onChangeAgree}
                                                            />}
                                                                          label="Oferta shartlariga roziman."/>
                                                    </FormControl>
                                                </FormGroup>

                                                <Typography
                                                style={{'color': 'red'}}
                                                id={"errors"}
                                                >

                                                </Typography>

                                                {!formShown && <Button
                                                    onClick={handleShowForm}
                                                    type='submit'
                                                    variant="contained"
                                                    sx={{mt: 3, mb: 2, px: 3}}
                                                >So'rovnomada ishtirok etish
                                                </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} className={!formShown? 'hidden' : 'shown'}>
                                      <Box
                                            sx={{
                                                marginTop: 3,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'left',
                                                px: 2
                                            }}>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="sex-label">Jinsingiz</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="sex-label"
                                                            name="sex"
                                                        >
                                                            <FormControlLabel value="female" name="sex" control={<Radio />} label="Ayol" />
                                                            <FormControlLabel value="male" name="sex" control={<Radio />} label="Erkak" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="school_class-label">Sinfingiz</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="school_class-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Tanlang..."
                                                            name="school_class"
                                                            required
                                                            fullWidth
                                                            id="school_class"
                                                            label="Sinfingiz"
                                                            options={[
                                                                // { value: 8, label: '8 - Sinf' },
                                                                // { value: 9, label: '9 - Sinf' },
                                                                { value: 10, label: '10 - Sinf' },
                                                                { value: 11, label: '11 - Sinf' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="which_otm-label">Maktabni bitirib qaysi OTMga kirmoqchisiz?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="which_otm-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Tanlang..."
                                                            name="which_otm"
                                                            required
                                                            fullWidth
                                                            id="which_otm"
                                                            label="Maktabni bitirib qaysi OTMga kirmoqchisiz?"
                                                            options={[
                                                                { value: 'davlat_otmlari', label: 'Davlat OTMlari' },
                                                                { value: 'xalqaro_otmlar', label: 'Xalqaro OTMlar' },
                                                                { value: 'texnikum', label: 'Texnikum' },
                                                                { value: 'chet_elga', label: 'Chet elda o\'qimoqchiman' },
                                                                { value: 'kirmoqchi_emasman', label: 'Kirmoqchi emasman' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="otm_path-label">Qaysi yo'nalishga topshirmoqchisiz?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="otm_path-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Tanlang..."
                                                            name="otm_path"
                                                            required
                                                            fullWidth
                                                            id="otm_path"
                                                            label="Qaysi yo'nalishga topshirmoqchisiz?"
                                                            options={[
                                                                { value: 'menejment', label: 'Biznes boshqaruv' },
                                                                { value: 'iqtisod', label: 'Iqtisodiyot' },
                                                                { value: 'ekonomika', label: 'Moliya' },
                                                                { value: 'it', label: 'IT' },
                                                                { value: 'pedagogika', label: 'Pedagogika' },
                                                                { value: 'huquqshunoslik', label: 'Huquqshunoslik' },
                                                                { value: 'tibbiyot', label: 'Tibbiyot' },
                                                                { value: 'turizm', label: 'Turizm' },
                                                                { value: 'sanat', label: 'San\'at' },
                                                                { value: 'qishloq_xojaligi', label: 'Qishloq xo\'jaligi' },
                                                                { value: 'arxitektura', label: 'Arxitektura' },
                                                                { value: 'muhandislik', label: 'Muhandislik' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel id="english_level-label">Ingliz tilini qay darajada bilasiz?</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="english_level-label"
                                                            // autoComplete="given-name"
                                                            placeholder="Tanlang..."
                                                            name="english_level"
                                                            required
                                                            fullWidth
                                                            id="english_level"
                                                            label="Ingliz tilini qay darajada bilasiz?"
                                                            options={[
                                                                { value: 'bilmayman', label: 'Bilmayman' },
                                                                { value: 'beginner', label: 'Beginner' },
                                                                { value: 'elementary', label: 'Elementary' },
                                                                { value: 'pre_intermediate', label: 'Pre-Intermediate' },
                                                                { value: 'intermediate', label: 'Intermediate' },
                                                                { value: 'advanced', label: 'Advanced' }
                                                            ]}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="prefer_contract_otm-label">Siz xohlagan OTMga kontrakt asosida qabul qilinsangiz, qancha miqdordagi kontrakt summasida o'qishga imkoningiz yetadi?</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="prefer_contract_otm-label"
                                                            name="prefer_contract_otm"
                                                        >
                                                            <FormControlLabel value="imkoniyatim_yoq" name="prefer_contract_otm" control={<Radio />} label="imkoniyatim yo'q" />
                                                            <FormControlLabel value="5_10_million" name="prefer_contract_otm" control={<Radio />} label="5-10 million" />
                                                            <FormControlLabel value="10_20_million" name="prefer_contract_otm" control={<Radio />} label="10-20 million" />
                                                            <FormControlLabel value="20_plus_million" name="prefer_contract_otm" control={<Radio />} label="20+ million" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl>
                                                        <FormLabel id="time_for_paid_courses-label">Darsdan tashqari pullik kurslarga qancha vaqt sarflaysiz?</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="time_for_paid_courses-label"
                                                            name="time_for_paid_courses"
                                                        >
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_6_12" control={<Radio />} label="6-12 Oy" />
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_3_6" control={<Radio />} label="3-6 Oy" />
                                                            <FormControlLabel name="time_for_paid_courses" value="mo_1_3" control={<Radio />} label="1-3 Oy" />
                                                            <FormControlLabel name="time_for_paid_courses" value="not_spend" control={<Radio />} label="Sarflamayman" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>

                                                {formShown && <Button
                                                    onClick={handleShowForm}
                                                    // fullWidth
                                                    variant="outlined"
                                                    sx={{mt: 3, mb: 2, px: 5}}
                                                >Orqaga
                                                </Button>
                                                }
                                                    <LoadingButton
                                                        type="submit"
                                                        loading={loading}
                                                        loadingPosition="end"
                                                        endIcon={<SendIcon />}
                                                        variant="contained"
                                                        sx={{mt: 3, ml: 2, mb: 2, px: 5}}
                                                    >
                                                        Yuborish
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {/*</form>*/}
                                </Box>
                                <Copyright />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src={sovga}
                                loading="lazy"
                                style={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}