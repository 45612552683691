import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Confirm from "./pages/Confirm";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import SalesFormView from "./pages/SalesFormView";
import Survey from "./pages/Survey";
import SurveyRu from "./pages/SurveyRu";
import SurveyView from "./pages/SurveyView";
import './index.css'
import About from "./pages/About";
import ReactGA from 'react-ga';
import {useEffect} from "react";
import ConfirmRu from "./pages/ConfirmRu";
const TRACKING_ID = "G-FQDTGCFQTV"; //
ReactGA.initialize(TRACKING_ID);

export default function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Survey />} />
                    <Route path="confirm" element={<Confirm />} />
                    <Route path="survey" element={<Survey />} />
                    <Route path="sales-form-view/:sId" element={<SalesFormView />} />
                    <Route path="survey-view" element={<SurveyView />} />
                    <Route path="blogs" element={<Blogs />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="about" element={<About />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
                <Route path="/ru" element={<Layout />}>
                    <Route index element={<SurveyRu />} />
                    <Route path="confirm-ru" element={<ConfirmRu />} />
                    <Route path="survey" element={<Survey />} />
                    <Route path="sales-form-view/:sId" element={<SalesFormView />} />
                    <Route path="survey-view" element={<SurveyView />} />
                    <Route path="blogs" element={<Blogs />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="about" element={<About />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

    // return <TestForm />
}

ReactDOM.render(<App />, document.getElementById("root"));