import Grid from "@mui/material/Grid";
import xtv from "../images/img.png";
import Typography from "@mui/material/Typography";
import idu from "../images/img_1.png";
import rtm from "../images/img_2.png";
import Card from "@mui/material/Card";
import {CardActionArea, Chip, Divider} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import Container from "@mui/material/Container";
import {InfoOutlined, Man, Phone} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";

function Contact() {
    return <Container component="main" maxWidth="lg" sx={{pb: 10}}>
        <Grid container
              spacing={2}
              sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
              direction="row"
              alignItems="center"
        >
            <Grid container
                  spacing={2}
                  sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
                  direction="row"
                  alignItems="center"
            >

                <Grid item xs={12} md={4}>
                    <img
                        src={xtv}
                        loading="lazy"
                        style={{width: '40%'}}
                    />
                    <Typography>O‘ZBEKISTON RESPUBLIKASI XALQ TA’LIMI VAZIRLIGI</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img
                        src={idu}
                        loading="lazy"
                        style={{width: '40%'}}
                    />
                    <Typography>TOSHKENT SHAHRIDAGI XALQARO RAQAMLI UNIVERSITET</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img
                        src={rtm}
                        loading="lazy"
                        style={{width: '40%'}}
                    />
                    <Typography>O‘QUVCHILARNI KASB-HUNARGA YO‘NALTIRISH VA PSIХOLOGIK-PEDAGOGIK RESPUBLIKA TASHXIS MARKAZI</Typography>
                </Grid>
            </Grid>
            <Card sx={{ width: '100%', mt: 3, textAlign: 'center' }}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="div">
                            Ishlab chiuvchi:
                        </Typography>
                        <Typography variant="body1" color="text.secondary">

                            <Divider />
                            <ListItemIcon>
                                <Man />
                                <Typography gutterBottom component="div">
                                    Shukrullo
                                </Typography>
                            </ListItemIcon>
                            <Divider />
                            <ListItemIcon>
                                <Phone />
                                <Typography gutterBottom component="div">
                                    +998 94 408-00-05
                                </Typography>
                            </ListItemIcon>
                            <Divider />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    </Container>
        }

export default Contact