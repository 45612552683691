import * as React from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Buffer} from "buffer";
// import Link from "@mui/material/Link";

function SurveyView() {
    return (<View />);
}

function View() {

    const username = 'ApiAdmin'
    const password = '*&87Y*&y98U89uj(*U'
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

    let [salesData, setSalesData] = useState([]);


    function createData(
        region,
        district,
        school,
        fullname,
        st_phone,
        parents_phone,
        agree,
        sex,
        school_class,
        which_otm,
        otm_path,
        english_level,
        math_level,
        prefer_contract_otm,
        time_for_paid_courses
    ) {
        return {
            region,
            district,
            school,
            fullname,
            st_phone,
            parents_phone,
            agree,
            sex,
            school_class,
            which_otm,
            otm_path,
            english_level,
            math_level,
            prefer_contract_otm,
            time_for_paid_courses
        };
    }
// console.log(salesData)
    let salesDataObj = {};
    if(salesData != null) {
        // console.log(salesData)
        salesDataObj = JSON.parse(salesData);
    }else {
        salesDataObj = {};
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log(salesDataStr)
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         salesDataObj = JSON.parse(salesData);
    //     }, 2000);
    // }, []);

    const rows = [
        createData(
            salesDataObj.region,
            salesDataObj.district,
            salesDataObj.school,
            salesDataObj.fullname,
            salesDataObj.st_phone,
            salesDataObj.parents_phone,
            salesDataObj.agree,
            salesDataObj.sex,
            salesDataObj.school_class,
            salesDataObj.which_otm,
            salesDataObj.otm_path,
            salesDataObj.english_level,
            salesDataObj.math_level,
            salesDataObj.prefer_contract_otm,
            salesDataObj.time_for_paid_courses)
    ];

    let params = useParams();
    // params.sId = 4
    const config = {
        method: 'get',
        url: 'http://localhost:8083/api/survey/1',
        // url: 'http://localhost:8083/api/survey/'+params.sId,
        // data: {},
        headers: {
            'Authorization': `Basic ${token}`
        }
    };

    let salesDataStr = '';
    axios(config)
        .then(function (response) {
            salesDataStr = JSON.stringify(response.data)
            console.log(salesData)
            setSalesData([salesDataStr])
            console.log(salesData)
            // return <h1>NOT FOUND!</h1>
        })
        .catch(function (error) {
            console.log(error);
            // return <h1>NOT FOUND!</h1>
        });

    // let result = undefined!==JSON.parse(salesData)[0]?JSON.parse(salesData)[0].manager_name:'No sale found by id: '+params.id

    // return <h1>{salesData?salesData:'Data with id: '+params.sId+' not found!'}</h1>;
    return (<>
        {salesData &&
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>region</TableCell>
                            <TableCell>district</TableCell>
                            <TableCell>school</TableCell>
                            <TableCell>fullname</TableCell>
                            <TableCell>st_phone</TableCell>
                            <TableCell>parents_phone</TableCell>
                            <TableCell>agree</TableCell>
                            <TableCell>sex</TableCell>
                            <TableCell>school_class</TableCell>
                            <TableCell>which_otm</TableCell>
                            <TableCell>otm_path</TableCell>
                            <TableCell>english_level</TableCell>
                            <TableCell>math_level</TableCell>
                            <TableCell>prefer_contract_otm</TableCell>
                            <TableCell>time_for_paid_courses</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.fullname}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.region}
                                </TableCell>
                                <TableCell align="right">{row.district}</TableCell>
                                <TableCell align="right">{row.school}</TableCell>
                                <TableCell align="right">{row.fullname}</TableCell>
                                <TableCell align="right">{row.st_phone}</TableCell>
                                <TableCell align="right">{row.parents_phone}</TableCell>
                                <TableCell align="right">{row.agree}</TableCell>
                                <TableCell align="right">{row.sex}</TableCell>
                                <TableCell align="right">{row.school_class}</TableCell>
                                <TableCell align="right">{row.which_otm}</TableCell>
                                <TableCell align="right">{row.otm_path}</TableCell>
                                <TableCell align="right">{row.english_level}</TableCell>
                                <TableCell align="right">{row.math_level}</TableCell>
                                <TableCell align="right">{row.prefer_contract_otm}</TableCell>
                                <TableCell align="right">{row.time_for_paid_courses}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        }
        {!salesData &&
            <h1>Noooo!</h1>
        }
    </>);
}

export default SurveyView
