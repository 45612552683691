import {Alert, AlertTitle, CardActionArea} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import xtv from "../images/img.png";
import jiu from '../images/jiu_logo_colored.png'
import rtm from "../images/img_2.png";
import cnfrm from "../images/img_3.png";
import cnfrm1 from "../images/confirm-2.png";
import cnfrm2 from "../images/confirm-3.png";
import ListItem from "@mui/material/ListItem";
import {Link} from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ReactSwipe from 'react-swipe';
import Button from "@mui/material/Button";

function Confirm() {
    let reactSwipeEl;
    return <Container component="main" maxWidth="lg" sx={{pb: 10}}>
        <Grid container
              spacing={2}
              sx={{ backgroundColor: 'white', width: '100%', mt: 3, ml: 0, textAlign: 'center', p: 3}}
              direction="row"
              alignItems="center"
        >

            <Grid item xs={12} md={4}>
                <img
                    src={xtv}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>O‘ZBEKISTON RESPUBLIKASI XALQ TA’LIMI VAZIRLIGI</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <img
                    src={jiu}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>SAMARQAND SHAHRIDAGI YAPONIYA XALQARO UNIVERSITETI</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <img
                    src={rtm}
                    loading="lazy"
                    style={{width: '40%'}}
                />
                <Typography>O‘QUVCHILARNI KASB-HUNARGA YO‘NALTIRISH VA PSIХOLOGIK-PEDAGOGIK RESPUBLIKA TASHXIS MARKAZI</Typography>
            </Grid>
        </Grid>
        <Alert severity="success" sx={{mt: 3}}>
            <AlertTitle><strong>Yuborildi!</strong></AlertTitle>
            Hurmatli o'quvchi tabriklaymiz siz muvaffaqiyatli ro'yxatdan o'tdingiz!

            <ListItem sx={{p: 0, textDecoration: 'none', mt: 2}}>
                <Link to="/survey" underline="none">
                    <ListItemText primary="Yangi so'rovnoma →"/>
                </Link>
            </ListItem>
        </Alert>


        <Grid container
              spacing={2}
              sx={{ backgroundColor: 'white', width: '100%', mt: 3, pb: 5, ml: 0, p: 3}}
        >
            <div style={{overflow: 'hidden'}}>
                <ReactSwipe
                    className="wrapper"
                    swipeOptions={{ continuous: true, auto: true, speed: 3000 }}
                    ref={el => (reactSwipeEl = el)}
                >
{/*
                    <div style={{backgroundImage: 'url('+cnfrm+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
*/}
                    <div style={{backgroundImage: 'url('+cnfrm1+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
                    <div style={{backgroundImage: 'url('+cnfrm2+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '600px', width: '100vw'}} className="child">
                    </div>
                </ReactSwipe>

                <Button
                    onClick={() => reactSwipeEl.prev()}
                    // fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2, px: 3, py: 1}}
                >&#8592;
                </Button>
                <Button
                    onClick={() => reactSwipeEl.next()}
                    // fullWidth
                    variant="contained"
                    id={"next-btn"}
                    sx={{mt: 3, mb: 2, px: 3, py: 1}}
                    style={{float: 'right'}}
                >&#8594;
                </Button>

            </div>
            <Typography gutterBottom variant="h6" component="div">
                O'ylaymizki albatta siz o'z tanlagan kasbingizda muvaffaqiyatlarga erishasiz.
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
                So'rovnoma tanlovi random shaklida o'tkazilib, har bir o'quvchi tantanali ravishda Xalq Ta'limi Vazirligi, Respublika tashxis markazi va JIU Universitet vakillari tomonidan taqdirlaniladi.
            </Typography>
        </Grid>
        </Container>
}

export default Confirm